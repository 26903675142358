var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('sequential-entrance',{attrs:{"fromTop":""}},[_c('SellerHeader',{attrs:{"to":"/seller","headerText":"Administrar peticiones"}})],1),_c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-container',{staticClass:"app-list"},[_vm._l((_vm.petitions),function(petitionGroup,indexGroup){return _c('v-container',{key:indexGroup},[_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(indexGroup))]),_vm._l((petitionGroup),function(petition,index){return _c('v-row',_vm._g({key:index,staticClass:"align-center my-5 d-flex justify-content-between rounded-lg",staticStyle:{"background-color":"#FAFAFA!important"},attrs:{"justify":"between"}},
                    petition.status == 'pending'
                      ? {
                          click: () =>
                            _vm.$bvModal.show(`modal-${indexGroup}-${index}`),
                        }
                      : { click: () => _vm.clickOnClosedPetition() }
                  ),[_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Evento: ")]),_vm._v(" "+_vm._s(petition.event.id)+" - "+_vm._s(petition.event.name)+" ")]),_c('p',{staticClass:"mb-1 text-color-soft-black"},[(petition.ticket)?_c('span',[_c('strong',[_vm._v("Ticket: ")]),_vm._v(" "+_vm._s(petition.ticket.id)+" - "),_c('strong',[_vm._v(_vm._s(petition.ticket.reference))])]):_vm._e(),(!petition.ticket)?_c('span',[_c('strong',[_vm._v("Ticket: ")]),_vm._v("  Ticket eliminado!")]):_vm._e()]),_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Tipo: ")]),(petition.type == 'delete')?_c('span',{staticClass:"text-uppercase red--text"},[_vm._v("Eliminar")]):_vm._e(),(petition.type == 'edit')?_c('span',{staticClass:"text-uppercase cyan--text"},[_vm._v("Editar")]):_vm._e()]),_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Remitente: ")]),_vm._v(" "+_vm._s(petition.seller_issuer.fullname)+" ")]),_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Motivo: ")]),_vm._v(" "+_vm._s(petition.reason)+" ")]),(petition.type == 'edit')?_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Cantidad de tickets solicitados: ")]),_vm._v(" "+_vm._s(petition.edit_quota)+" ")]):_vm._e(),(petition.seller_moderator)?_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Usuario moderador: ")]),_vm._v(" "+_vm._s(petition.seller_moderator.fullname)+" ")]):_vm._e(),(petition.created_at)?_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Fecha de creación: ")]),_vm._v(" "+_vm._s(_vm.date(petition.created_at))+" ")]):_vm._e(),(
                          petition.updated_at &&
                            petition.updated_at != petition.created_at
                        )?_c('p',{staticClass:"mb-1 text-color-soft-black"},[_c('strong',[_vm._v("Última actualización: ")]),_vm._v(" "+_vm._s(_vm.date(petition.updated_at))+" ")]):_vm._e()]),_c('b-modal',{ref:"my-modal",refInFor:true,attrs:{"centered":"","id":'modal-' + indexGroup + '-' + index,"title":"Confirmar petición","header-bg-variant":"dark","header-text-variant":"light","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"font-italic"},[_vm._v(" Si se aprueba la petición se aplicarán los cambios de forma automática ")]),_c('b-button',{staticClass:"mx-3",attrs:{"variant":"success"},on:{"click":function($event){_vm.approvePetition(petition.id)
                            _vm.$bvModal.hide(`modal-${indexGroup}-${index}`)}}},[_vm._v("Aprobar")]),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"danger"},on:{"click":function($event){_vm.rejectPetition(petition.id)
                            _vm.$bvModal.hide(`modal-${indexGroup}-${index}`)}}},[_vm._v("Rechazar")])],1)])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('div',[(petition.status == 'approved')?_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_vm._v("APROBADO")]):_vm._e(),(petition.status == 'rejected')?_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v("RECHAZADO")]):_vm._e(),(petition.status == 'pending')?_c('b-badge',{attrs:{"pill":"","variant":"warning"}},[_vm._v("PENDIENTE")]):_vm._e()],1)])],1)})],2)}),(_vm.loading)?_c('v-row',_vm._l((_vm.skeletonCount),function(skeleton,index){return _c('v-container',{key:index,staticClass:"align-center d-flex justify-content-between rounded-lg mb-5",staticStyle:{"background-color":"#FAFAFA!important"},attrs:{"justify":"between"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('b-skeleton',{attrs:{"width":"90%"}}),_c('b-skeleton',{attrs:{"width":"50%"}}),_c('b-skeleton',{attrs:{"width":"30%"}})],1),_c('v-col',{staticClass:"pl-0 text-center",attrs:{"cols":"2"}},[_c('b-skeleton',{attrs:{"type":"avatar"}})],1)],1)}),1):_vm._e()],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }