
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getPetitions, updatePetition } from '@/services/seller.service'
import { idoDateToDateAndHours } from '@/helpers/date'
import { ErrorMessages } from '@/helpers/constants'
import { getMyData } from '@/services/user.service'
import { UserI } from '@/interfaces/user.interface'
import _ from 'lodash'

@Component({
  components: {
    SellerHeader,
  },
})
export default class PetitionsList extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')
  petitions: [] = []
  user: UserI
  public skeletonCount = [0, 1, 2, 3, 4]
  public loading = false

  hideModal() {
    this.$refs['my-modal'].$refs['close-button'].click()
  }

  async getPetitions() {
    try {
      this.loading = true
      const data = await getPetitions()
      const petitions = data.data.data

      this.petitions = await _.groupBy(
        petitions,
        petition => petition.event.name
      )
      this.loading = false
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  date(isoDate: string) {
    return idoDateToDateAndHours(isoDate)
  }

  mounted() {
    this.getPetitions()
    this.getMyData()
  }

  async getMyData() {
    try {
      const { data } = await getMyData()
      this.user = data
    } catch (e) {
      console.error(e)
    }
  }

  async approvePetition(petitionId: number) {
    const body = {
      status: 'approved',
      user_id_moderator: this.user.id,
    }
    const response = await updatePetition(petitionId, body)

    if (response.status == '200') {
      this.getPetitions()
      Vue.$toast.success(`Petición aprobada correctamente`)
    } else {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async rejectPetition(petitionId: number) {
    const body = {
      status: 'rejected',
      user_id_moderator: this.user.id,
    }
    const response = await updatePetition(petitionId, body)
    if (response.status == '200') {
      this.getPetitions()
      Vue.$toast.success(`Petición rechazada correctamente`)
    } else {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  clickOnClosedPetition() {
    Vue.$toast.warning(`No se puede editar una petición cerrada`)
  }
}
